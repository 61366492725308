import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import { logoutHandler } from "layout/sidebar/AuthContext";
import { Toast } from "@chakra-ui/react";
// 새로운 액세스 토큰을 저장하고 axios 기본 헤더를 갱신하는 함수
function updateAuthToken(token: string) {
  localStorage.setItem("token", token); // 새로운 액세스 토큰을 localStorage에 저장
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`; // axios 인스턴스 기본 헤더 갱신
}

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

// 리프레시 토큰을 사용해 새로운 액세스 토큰을 가져오는 함수
async function refreshToken() {
  try {
    const refreshToken = localStorage.getItem("refreshToken"); // 리프레시 토큰 가져오기
    if (!refreshToken) {
      throw new Error("No refresh token available");
    }

    // 리프레시 토큰을 Authorization 헤더에 담아서 보내기
    const { data } = await axios.post('http://localhost:8080/refresh_token', {}, {
    
    //const { data } = await axios.post('/refresh_token', {}, {
      headers: {
        'Authorization': `Bearer ${refreshToken}`, // Authorization 헤더에 리프레시 토큰 담기
        'Content-Type': 'application/json',
      }
    });

    if (data.Token) {
      updateAuthToken(data.Token); // 새로운 액세스 토큰을 저장 및 axios 헤더 갱신
    }

    return data.Token;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
}

// axios 인스턴스 생성
const springAxiosInst: AxiosInstance = axios.create({
  //baseURL: "http://localhost:8080",
  baseURL: "https://loaschedule.site",
  timeout: 5000,
  headers: {
    "Authorization": `Bearer ${localStorage.getItem("token")}`, // localStorage에서 토큰 가져오기
    "Content-Type": "application/json",
  },
});

// axios 응답 인터셉터
springAxiosInst.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError<unknown, any>) => {
    const originalRequest = error.config as CustomAxiosRequestConfig & AxiosRequestConfig;

    // 401 Unauthorized 에러 처리
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newToken = await refreshToken(); // 리프레시 토큰을 통해 새로운 액세스 토큰을 받아옴

        if (newToken && originalRequest.headers) {
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`; // 새로운 액세스 토큰을 요청 헤더에 설정
        }

        // axios 인스턴스의 기본 헤더도 갱신하여 이후 모든 요청에 새로운 토큰 사용
        springAxiosInst.defaults.headers['Authorization'] = `Bearer ${newToken}`;
        // 원래 요청을 새로운 토큰으로 재시도
        return springAxiosInst(originalRequest);
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);

        // Token refresh 실패 시 로그아웃 처리
        Toast({
          title: "세션이 만료되었습니다. 다시 로그인해주세요.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        logoutHandler();
        return Promise.reject(refreshError);
      }
    }

    // 그 외의 에러는 그대로 전달
    return Promise.reject(error);
  }
);

export default springAxiosInst;

export function isAxiosError(error: any): error is AxiosError {
  return error.isAxiosError === true;
}
