import React, { useEffect } from 'react';
import RaidManagement from './RaidManagement';
import RaidGroupSchedule from './RaidGroupSchedule';
import { useAuth } from 'layout/sidebar/AuthContext';
import LoginModal from 'utility/LoginModal';
import { useDisclosure, Flex, Spinner } from '@chakra-ui/react';

const Main: React.FC = () => {
  const { isLoggedIn, isLoading } = useAuth();
  const loginModal = useDisclosure();

  useEffect(() => {
    if (!isLoading && !isLoggedIn) {
      loginModal.onOpen();
    }
  }, [isLoggedIn, isLoading]);

  if (isLoading) {
    return (
      <Flex justify="center" align="center" h="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <div>
      <LoginModal isOpen={loginModal.isOpen} onClose={loginModal.onClose} />
      {isLoggedIn && (
        <>
          <RaidManagement />
          <RaidGroupSchedule />
        </>
      )}
    </div>
  );
};

export default Main;
