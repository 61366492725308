import { RaidDifficulty } from '../entity/ScheduleCreate';

export const translateDifficulty = (difficulty: RaidDifficulty) => {
    switch (difficulty) {
      case RaidDifficulty.NORMAL:
        return '노말';
      case RaidDifficulty.HARD:
        return '하드';
      case RaidDifficulty.HELL:
        return '헬';
      default:
        return 'Unknown';
    }
  };