import React, { useState, useEffect } from 'react';
import { Box, Flex, Button, Input, Image, VStack, Text, Heading, useColorModeValue, Spinner, useToast, Stack, useDisclosure } from '@chakra-ui/react';
import { CharacterDTO } from 'myinfo/entity/characterDto';
import { fetchAllCharacters, fetchCharactersByName } from 'myinfo/api/MyInfoApi';
import { useAuth } from 'layout/sidebar/AuthContext';
import LoginModal from 'utility/LoginModal';

const MyInfo: React.FC = () => {
  const { isLoggedIn, isLoading } = useAuth();
  const [characters, setCharacters] = useState<CharacterDTO[]>([]);
  const [playerName, setPlayerName] = useState('');
  const [loading, setLoading] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const toast = useToast();
  const loginModal = useDisclosure();

  // Fetch characters on component mount with GET request
  useEffect(() => {
    // Prevent useEffect from running before the auth state is fully determined
    if (isLoading || initialLoadDone) return;

    if (!isLoggedIn) {
      loginModal.onOpen();
      setInitialLoadDone(true);
      return;
    }

    const loadInitialData = async () => {
      setLoading(true);
      try {
        const characters = await fetchAllCharacters();
        setCharacters(characters);
      } catch (error: any) {
        toast({
          title: '캐릭터 로딩 실패',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        console.error('Error loading character details:', error);
      } finally {
        setLoading(false);
        setInitialLoadDone(true);
      }
    };

    loadInitialData();
  }, [isLoggedIn, isLoading, loginModal, toast, initialLoadDone]);

  const fetchCharacterDetails = async () => {
    if (!isLoggedIn) {
      loginModal.onOpen();
      return;
    }
    if (!playerName) return;
    setLoading(true);
    try {
      const characters = await fetchCharactersByName(playerName);
      setCharacters(characters);
    } catch (error: any) {
      toast({
        title: '캐릭터 로딩 실패',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const cardBg = useColorModeValue('white', 'gray.700');
  const buttonBg = useColorModeValue('green.400', 'green.300');
  const buttonHoverBg = useColorModeValue('green.500', 'green.400');
  const textColor = useColorModeValue('gray.800', 'white');
  const headerColor = useColorModeValue('teal.600', 'teal.300');
  const inputBg = useColorModeValue('white', 'gray.600');
  const borderColor = useColorModeValue('gray.300', 'gray.500');
  const placeholderColor = useColorModeValue('gray.500', 'gray.400');

  if (isLoading) {
    return (
      <Flex justify="center" align="center" minH="100vh">
        <Spinner size="xl" color={headerColor} />
      </Flex>
    );
  }

  return (
    <Box textAlign="center" color={textColor} minH="100vh" py="5" px="2">
      <LoginModal isOpen={loginModal.isOpen} onClose={loginModal.onClose} />
      <Flex direction="column" align="center" mb="5">
        <Heading mb="5" color={headerColor}>
          캐릭터 목록 업데이트
        </Heading>
        <Stack direction={{ base: 'column', md: 'row' }} spacing="3" align="center">
          <Input
            placeholder="캐릭터명 입력"
            value={playerName}
            onChange={(e) => setPlayerName(e.target.value)}
            size="lg"
            width={{ base: 'full', md: 'auto' }}
            borderColor={borderColor}
            color={textColor}
            bg={inputBg}
            _placeholder={{ color: placeholderColor }}
          />
          <Button
            onClick={fetchCharacterDetails}
            isLoading={loading}
            loadingText="Loading"
            bg={buttonBg}
            color="white"
            _hover={{ bg: buttonHoverBg }}
          >
            불러오기
          </Button>
        </Stack>
      </Flex>
      {loading ? (
        <Flex justify="center" align="center" minH="60vh">
          <Spinner size="xl" color={headerColor} />
        </Flex>
      ) : (
        <Flex justify="center" wrap="wrap" gap="4">
          {characters.map((character) => (
            <Box
              key={character.characterName}
              bg={cardBg}
              borderRadius="lg"
              overflow="hidden"
              shadow="xl"
              maxWidth="300px"
              transition="transform 0.3s, box-shadow 0.3s"
              _hover={{ transform: 'scale(1.05)', shadow: '2xl' }}
            >
              <Image src={character.characterImage} alt={character.characterName} width="100%" />
              <VStack p="4" align="start" spacing="4">
                <Heading size="md" color={textColor}>
                  {character.characterName}
                </Heading>
                <Text color={textColor}>{character.characterClassName}</Text>
                <Text color={textColor}>템렙: {character.itemMaxLevel}</Text>
              </VStack>
            </Box>
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default MyInfo;
