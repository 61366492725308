import React, { useState, useEffect } from 'react';
import {
  Box, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton,
  SimpleGrid, Text, Image, useDisclosure, useToast, Center, Flex, VStack
} from '@chakra-ui/react';
import { RaidGroupMembers } from 'RaidGroup/entity/RaidMember';
import { CharacterDTO } from 'myinfo/entity/characterDto';
import { fetchRaidGroupMembers } from 'RaidGroup/api/RaidGroupApi';
import { fetchCharactersByIdAndRaidId } from 'myinfo/api/MyInfoApi';


interface RaidGroupMembersSelectModalProps {
  raidGroupId?: number;
  raidId?: number;  // raidId 추가
  onCharacterSelect: React.Dispatch<React.SetStateAction<CharacterDTO[]>>;
}

export const RaidCharacterSelect: React.FC<RaidGroupMembersSelectModalProps> = ({ raidGroupId, raidId, onCharacterSelect }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [members, setMembers] = useState<RaidGroupMembers[]>([]);
  const [characters, setCharacters] = useState<CharacterDTO[]>([]);
  const [selectedCharacters, setSelectedCharacters] = useState<CharacterDTO[]>([]);
  const toast = useToast();

  useEffect(() => {
    if (!raidGroupId) {
      return; // raidGroupId가 없다면 여기서 함수 실행을 중지
    }
    const loadMembers = async () => {
      try {
        const loadedMembers = await fetchRaidGroupMembers(raidGroupId);
        setMembers(loadedMembers);
      } catch (error) {
        console.error('Failed to fetch raid group members', error);
        toast({
          title: '에러',
          description: '레이드 그룹 멤버를 불러오는 중 문제가 발생했습니다.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };
    loadMembers();
  }, [raidGroupId]);

  const handleDeselectCharacter = (characterName: string) => {
    const newSelectedCharacters = selectedCharacters.filter(char => char.characterName !== characterName);
    setSelectedCharacters(newSelectedCharacters);
    onCharacterSelect(newSelectedCharacters);

    toast({
      title: '캐릭터 선택 취소됨',
      description: `${characterName}이 선택 취소되었습니다.`,
      status: 'info',
      duration: 4000,
      isClosable: true,
    });
  };

  const handleCharacterSelect = (character: CharacterDTO) => {
    // 이미 할당된 캐릭터
    if (character.isAssigned) {
      toast({
        title: '이미 사용된 캐릭터입니다',
        description: `${character.characterName}는 이미 이 레이드에 할당되었습니다.`,
        status: 'warning',
        duration: 4000,
        isClosable: true,
      });
      return;
    }

    if (selectedCharacters.some(selected => selected.characterName === character.characterName)) {
      handleDeselectCharacter(character.characterName);
      return;
    }
    setSelectedCharacters(prevCharacters => [...prevCharacters, character]);
    onCharacterSelect(prevCharacters => [...prevCharacters, character]);
    toast({
      title: '캐릭터 선택됨',
      description: `${character.characterName}이 선택되었습니다.`,
      status: 'success',
      duration: 4000,
      isClosable: true,
    });
    onClose();
  };

  const handleMemberClick = async (userId: number) => {
    if (!raidId) {
      toast({
        title: '에러',
        description: '레이드를 먼저 선택해주세요.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const loadedCharacters = await fetchCharactersByIdAndRaidId(userId, raidId);
      setCharacters(loadedCharacters);
    } catch (error) {
      console.error('Failed to fetch characters', error);
      toast({
        title: '에러',
        description: '캐릭터 정보를 불러오는 중 문제가 발생했습니다.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Center>
        <Button colorScheme="green" onClick={onOpen} mt={4}>레이드 멤버 편성</Button>
      </Center>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>멤버 선택</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={4} spacing={4}>
              {members.map(member => (
                <Box key={member.id} p={3} borderWidth="1px" borderRadius="lg" shadow="md" cursor="pointer" onClick={() => handleMemberClick(member.id)}>
                  <Text fontSize="lg" fontWeight="bold">{member.username}</Text>
                </Box>
              ))}
            </SimpleGrid>
            {characters.length > 0 && (
              <SimpleGrid columns={4} spacing={2} mt={4}>
                {characters.map(character => (
                  <Box key={character.id}
                    p={3}
                    borderWidth="1px"
                    borderRadius="lg"
                    shadow="md"
                    cursor={character.isAssigned ? "not-allowed" : "pointer"}
                    opacity={character.isAssigned ? 0.5 : 1}
                    onClick={() => handleCharacterSelect(character)}
                  >
                    <VStack spacing={2} align="center">
                      <Image src={character.characterImage} alt={character.characterName} boxSize="150px" objectFit="cover" />
                      {selectedCharacters.some(selected => selected.characterName === character.characterName) && (
                        <Flex direction="column" align="center" mt={2}>
                          <Button size="sm" colorScheme="red" onClick={(e) => {
                            e.stopPropagation();
                            handleDeselectCharacter(character.characterName);
                          }}>선택 취소</Button>
                        </Flex>
                      )}
                      <Text fontSize="lg" fontWeight="bold">{character.characterName}</Text>
                      <Text fontSize="sm">{character.characterClassName} ({character.itemMaxLevel})</Text>
                    </VStack>
                  </Box>
                ))}
              </SimpleGrid>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RaidCharacterSelect;