import React from 'react';
import {
  Box,
  CloseButton,
  Flex,
  Icon,
  Link,
  Text,
  useColorModeValue,
  useColorMode,
  IconButton,
  Divider,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { FiHome, FiTrendingUp, FiCompass, FiStar, FiLogIn, FiLogOut, FiMoon, FiSun } from 'react-icons/fi';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

interface LinkItemProps {
  name: string;
  icon: IconType;
  link: string;
}

interface SidebarProps {
  onClose: () => void;
  isMobile: boolean;
  isOpen: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ onClose, isMobile, isOpen }) => {
  const { logout, isLoggedIn } = useAuth();
  const { colorMode, toggleColorMode } = useColorMode();
  const bg = useColorModeValue('gray.50', 'gray.900');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const hoverBg = useColorModeValue('blue.400', 'blue.600');
  const hoverTextColor = useColorModeValue('white', 'gray.100');
  const textColor = useColorModeValue('gray.800', 'white');
  const subTextColor = useColorModeValue('gray.600', 'gray.400');
  const activeLinkBg = useColorModeValue('blue.500', 'blue.700');

  const linkItems: Array<LinkItemProps> = [
    { name: '홈', icon: FiHome, link: '/' },
    { name: '내 정보', icon: FiStar, link: '/myinfo' },
    { name: '레이드 관리', icon: FiTrendingUp, link: '/raidmanagement' },
    { name: '공대관리', icon: FiCompass, link: '/raidgroup' },
    { name: isLoggedIn ? '로그아웃' : '로그인', icon: isLoggedIn ? FiLogOut : FiLogIn, link: isLoggedIn ? '#' : '/signin' },
  ];

  const handleLogoutClick = () => {
    logout();
  };

  return (
    <Box
      bg={bg}
      borderRight="1px"
      borderRightColor={borderColor}
      w={{ base: '80%', md: '256px' }} // 사이드바 너비를 고정 너비로 설정 (256px)
      pos="fixed"
      top="50%"
      left={isMobile && !isOpen ? '-100%' : 0} // 사이드바가 닫힌 경우 화면 밖으로 숨김
      h="90vh"
      transition="left 0.3s ease-in-out" // 열고 닫을 때 애니메이션 추가
      transform="translateY(-50%)"
      zIndex={1000} // 화면 위에 표시되도록 설정
      p={4}
      ml={{ base: 16, md: 0 }}
      shadow="2xl"
      borderRadius={{ base: 0, md: 'lg' }}
      color={textColor}
    >
      <Flex h="20" flexDirection="column" justifyContent="center" mx="8" mb={4}>
        <Text fontSize="2xl" fontWeight="extrabold" mb={1}>
          숙제조와
        </Text>
        <Text fontSize="md" fontWeight="medium" color={subTextColor}>
          {isLoggedIn ? `${localStorage.getItem('userNickname')}님 환영합니다` : '로그인해주세요'}
        </Text>
      </Flex>
      <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} position="absolute" right="8px" top="8px" />

      {linkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          link={link.link}
          hoverBg={hoverBg}
          hoverTextColor={hoverTextColor}
          activeLinkBg={activeLinkBg}
          onClick={link.name === '로그아웃' ? handleLogoutClick : undefined}
        >
          {link.name}
        </NavItem>
      ))}

      <Divider my={6} borderColor={borderColor} />

      <Flex justifyContent="center" alignItems="center" p="4" >
        <IconButton
          aria-label={`Switch to ${colorMode === 'light' ? 'dark' : 'light'} mode`}
          icon={colorMode === 'light' ? <FiMoon /> : <FiSun />}
          onClick={toggleColorMode}
          isRound={true}
          _hover={{ bg: hoverBg }}
          transition="background 0.3s ease"
        />
      </Flex>
    </Box>
  );
};

interface NavItemProps {
  icon: IconType;
  children: React.ReactNode;
  link: string;
  hoverBg: string;
  hoverTextColor: string;
  activeLinkBg: string;
  onClick?: () => void;
}

const NavItem: React.FC<NavItemProps> = ({ icon, children, link, hoverBg, hoverTextColor, activeLinkBg, onClick }) => {
  const location = useLocation(); // 현재 경로 정보를 가져옴
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  };

  const isActive = location.pathname === link;

  return (
    <Link
      as={RouterLink}
      to={link === '#' ? '/' : link}
      style={{ textDecoration: 'none' }}
      onClick={handleClick}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        my="2"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        bg={isActive ? activeLinkBg : undefined}
        color={isActive ? 'white' : undefined}
        _hover={{
          bg: hoverBg,
          color: hoverTextColor,
          boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
          transform: 'translateY(-3px)',
        }}
        transition="background 0.3s ease, transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out"
      >
        {icon && <Icon mr="4" fontSize="20" as={icon} />}
        <Text fontWeight="semibold">{children}</Text>
      </Flex>
    </Link>
  );
};

export default Sidebar;
