import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  Image,
  VStack,
  useColorModeValue,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react';
import { CompletedStatus, RaidDifficulty } from './entity/ScheduleCreate';
import { Schedules } from './entity/Schedules';
import { deleteSchdeduleByCharacterName, fetchScheduleCharacters, completeSchedule, deleteSchedule } from './api/ScheduleApi';
import CharacterBatch from './modals/CharactersBatch';
import { CharacterDTO } from 'myinfo/entity/characterDto';
import { fetchCharactersBycharacterId } from 'myinfo/api/MyInfoApi';
import { translateDifficulty } from './common/ManageFunc';
import useChakraToast from 'utility/useChakraToast';

const ScheduleCard: React.FC<Schedules> = ({
  id,
  scheduleName,
  createdBy,
  raidDifficulty,
  raidName,
  raidImage,
  scheduleDateTime,
  completedStatus,
  characterName,
  isOwner,
}) => {
  const dateTime = new Date(scheduleDateTime).toLocaleString('ko-KR', {
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

  const showToast = useChakraToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedCharacters, setSelectedCharacters] = useState<CharacterDTO[]>([]);
  const [maxPlayers, setMaxPlayers] = useState(8);
  const cardBg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'white');
  const detailColor = useColorModeValue('gray.500', 'gray.300');

  const handleDeleteByCharacter = async () => {
    try {
      await deleteSchdeduleByCharacterName(characterName);
      showToast({
        title: '스케줄 취소 성공',
        description: `${characterName}의 스케줄이 취소되었습니다.`,
        status: 'success',
      });
    } catch (error: any) {
      showToast({
        title: '오류 발생',
        description: '스케줄 취소 중 문제가 발생했습니다: ' + error.message,
        status: 'error',
      });
    }
  };

  const handleDeleteSchedule = async () => {
    try {
      await deleteSchedule(id);
      showToast({
        title: '스케줄 삭제 성공',
        description: '스케줄이 완전히 삭제되었습니다.',
        status: 'success',
      });
    } catch (error: any) {
      showToast({
        title: '오류 발생',
        description: '스케줄 삭제 중 문제가 발생했습니다: ' + error.message,
        status: 'error',
      });
    }
  };

  const handleComplete = async () => {
    try {
      await completeSchedule(id);
      showToast({
        title: '레이드 완료',
        description: '레이드가 완료되었습니다.',
        status: 'success',
      });
    } catch (error: any) {
      showToast({
        title: '오류 발생',
        description: '레이드 완료 처리 중 문제가 발생했습니다: ' + error.message,
        status: 'error',
      });
    }
  };

  const handleScheduleClick = async () => {
    try {
      const scheduleDetails = await fetchScheduleCharacters(id);
      const { characterIds, maxPlayers } = scheduleDetails;

      setMaxPlayers(maxPlayers);

      const fetchedCharacters = await Promise.all(
        characterIds.map(async (member) => {
          const characterDetails = await fetchCharactersBycharacterId(member.characterId);
          return {
            ...characterDetails,
            characterIndex: member.characterIndex,
          };
        })
      );

      setSelectedCharacters(fetchedCharacters);
      onOpen();
    } catch (error) {
      console.error('Failed to fetch schedule characters', error);
      showToast({
        title: '오류 발생',
        description: '캐릭터 정보를 불러오는 중 문제가 발생했습니다.',
        status: 'error',
      });
    }
  };

  const saveAssignment = (parties: (CharacterDTO | null)[][]) => {
    console.log('Saved Parties:', parties);
  };

  const difficultyLabel = translateDifficulty(raidDifficulty);

  return (
    <>
      <Box
        p={4}
        shadow={completedStatus === CompletedStatus.COMPLETED ? 'none' : 'md'}
        bg={completedStatus === CompletedStatus.COMPLETED ? 'gray.800' : cardBg}
        borderRadius="xl"
        borderWidth="2px"
        borderColor={completedStatus === CompletedStatus.COMPLETED ? 'gray.500' : 'gray.400'}
        overflow="hidden"
        _hover={{
          transform: completedStatus === CompletedStatus.COMPLETED ? 'none' : 'scale(1.05)',
          boxShadow: completedStatus === CompletedStatus.COMPLETED ? 'none' : '2xl',
        }}
        transition="all 0.3s ease-in-out"
        onClick={completedStatus !== CompletedStatus.COMPLETED ? (e) => { e.stopPropagation(); handleScheduleClick(); } : undefined}
      >
        <VStack spacing={3} align="center" w="full">
          <Heading fontSize="xl" fontWeight="bold" color={textColor} mb={1}>
            {scheduleName}
          </Heading>
          <Image borderRadius="full" boxSize="80px" src={raidImage} alt={raidName} />
          <Text fontSize="md" color={detailColor} fontWeight="medium">
            {raidName} ({difficultyLabel})
          </Text>
          <Text fontSize="md" color={detailColor} fontWeight="medium">
            공대장 : {createdBy}
          </Text>
          <Text fontSize="md" color={detailColor} fontWeight="medium">
            참가 캐릭터: {characterName}
          </Text>
          <Text fontSize="md" color={detailColor}>
            {dateTime}
          </Text>
          {completedStatus === CompletedStatus.COMPLETED ? (
            <Text fontSize="lg" color="green.500" fontWeight="bold">
              완료된 레이드
            </Text>
          ) : completedStatus === CompletedStatus.CANCELLED ? (
            <Text fontSize="lg" color="red.500" fontWeight="bold">
              취소된 레이드
            </Text>
          ) : (
            <>
              <Button
                mt={3}
                colorScheme="red"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteByCharacter();
                }}
              >
                참가 취소
              </Button>
              {isOwner && (
                <Button
                  mt={3}
                  colorScheme="green"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleComplete();
                  }}
                >
                  레이드 완료
                </Button>
              )}
              <Button
                mt={3}
                colorScheme="red"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteSchedule();
                }}
              >
                스케줄 삭제
              </Button>
            </>
          )}
        </VStack>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>캐릭터 배치 수정</ModalHeader>
          <ModalBody>
            <CharacterBatch
              maxPlayers={maxPlayers}
              characters={selectedCharacters}
              saveAssignment={saveAssignment}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>닫기</Button>
            <Button
              colorScheme="blue"
              ml={3}
              onClick={() => {
                onClose();
                saveAssignment([selectedCharacters]);
              }}
            >
              저장
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ScheduleCard;