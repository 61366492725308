import React, { useEffect, useState } from 'react';
import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Text, Image, Box, VStack, HStack,
  Avatar, useColorModeValue, Stack, useToast, Heading, Divider
} from '@chakra-ui/react';
import { RaidGroup } from './entity/RaidGroup';
import { RaidGroupMember, RaidGroupMembers } from './entity/RaidMember';
import { acceptRaidMember, fetchRaidGroupMemberStatus, getRaidMemberRequests, leaveRaidGroup, deleteRaidGroup, rejectRaidMember } from './api/RaidGroupApi';

interface RaidGroupDetailsModalProps {
  raidGroup: RaidGroup;
  isOpen: boolean;
  onClose: () => void;
  onApply: (raidGroupMember: RaidGroupMember) => void;
}

const RaidGroupDetailsModal: React.FC<RaidGroupDetailsModalProps> = ({ raidGroup, isOpen, onClose, onApply }) => {
  const [isMember, setIsMember] = useState(false);
  const [isMaster, setIsMaster] = useState(false);
  const [requests, setRequests] = useState<RaidGroupMembers[]>([]);
  const toast = useToast();
  const modalBg = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const buttonColorScheme = useColorModeValue('blue', 'teal');
  const sectionBg = useColorModeValue('gray.50', 'gray.700');
  const dividerColor = useColorModeValue('gray.300', 'gray.600');
  const requestBg = useColorModeValue('white', 'gray.600');

  useEffect(() => {
    const checkMembership = async () => {
      try {
        const membersRaidGroups = await fetchRaidGroupMemberStatus();
        const isMember = membersRaidGroups.some((group: RaidGroup) => group.id === raidGroup.id);
        const isMaster = membersRaidGroups.some((group: RaidGroup) => group.id === raidGroup.id && group.master);
        setIsMember(isMember);
        setIsMaster(isMaster);

        if (isMaster) {
          const requestsData = await getRaidMemberRequests(raidGroup.id!);
          setRequests(requestsData);
        }
      } catch (error) {
        console.error('Error fetching membership status:', error);
      }
    };

    if (raidGroup && isOpen) {
      checkMembership();
    }
  }, [raidGroup, isOpen]);

  const handleApplyClick = () => {
    const raidGroupMember: RaidGroupMember = {
      raidGroupId: raidGroup.id ?? 0,
    };
    onApply(raidGroupMember);
  };

  const handleAccept = async (userId: number) => {
    try {
      const acceptanceData = {
        raidGroupId: raidGroup.id ?? 0,
        userId: userId,
      };
      await acceptRaidMember(acceptanceData);
      setRequests((prev) => prev.filter((request) => request.id !== userId));
      toast({
        title: '가입 승인 완료',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: '가입 승인 실패',
        description: '승인 과정 중 오류가 발생했습니다.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleReject = async (userId: number) => {
    try {
      const rejectionData = {
        raidGroupId: raidGroup.id ?? 0,
        userId: userId,
      };
      await rejectRaidMember(rejectionData);
      setRequests((prev) => prev.filter((request) => request.id !== userId));
      toast({
        title: '가입 거절 완료',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: '가입 거절 실패',
        description: '거절 과정 중 오류가 발생했습니다.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }
    

  const handleLeave = async () => {
    try {
      await leaveRaidGroup(raidGroup.id ?? 0);
      toast({
        title: '공대 탈퇴 완료',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        title: '공대 탈퇴 실패',
        description: '탈퇴 과정 중 오류가 발생했습니다.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDisband = async () => {
    try {
      await deleteRaidGroup(raidGroup.id ?? 0);
      toast({
        title: '공대 해산 완료',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        title: '공대 해산 실패',
        description: '해산 과정 중 오류가 발생했습니다.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent bg={modalBg} borderRadius="xl" boxShadow="2xl">
        <ModalHeader textAlign="center" fontWeight="bold">{raidGroup.raidGroupName}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={6} align="stretch">
            <Box borderRadius="lg" overflow="hidden" boxShadow="md">
              <Image src={raidGroup.raidGroupImage} alt={raidGroup.raidGroupName} maxH="200px" w="full" objectFit="cover" />
              <Text mt={4} p={4} color={textColor} textAlign="justify">{raidGroup.raidGroupDescription}</Text>
            </Box>

            {isMaster && (
              <VStack spacing={4} bg={sectionBg} p={4} borderRadius="lg">
                <Heading size="md" color={textColor} mb={2} textAlign="center">가입 요청</Heading>
                {requests.length > 0 ? (
                  <Stack spacing={3} w="full">
                    {requests.map((request) => (
                      <HStack key={request.id} justify="space-between" w="full" p={3} boxShadow="md" borderRadius="md" bg={requestBg}>
                        <HStack spacing={3} alignItems="center">
                          <Avatar size="md" name={request.username} />
                          <VStack spacing={0} alignItems="flex-start">
                            <Text fontWeight="bold" color={textColor}>{request.username}</Text>
                            <Text fontSize="sm" color="gray.500">가입 요청</Text>
                          </VStack>
                        </HStack>
                        <HStack spacing={2}>
                          <Button colorScheme="green" variant="outline" onClick={() => handleAccept(request.id)}>
                            승인
                          </Button>
                          <Button colorScheme="red" variant="outline" onClick={() => handleReject(request.id)}>
                            거절
                          </Button>
                        </HStack>
                      </HStack>
                    ))}
                  </Stack>
                ) : (
                  <Text color="gray.500" textAlign="center">가입 요청이 없습니다.</Text>
                )}
              </VStack>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <VStack w="full">
            <Divider borderColor={dividerColor} />
            <HStack w="full" justifyContent="space-between">
              {!isMember && (
                <Button colorScheme={buttonColorScheme} onClick={handleApplyClick} w="full">
                  가입 신청
                </Button>
              )}
              {isMember && !isMaster && (
                <Button colorScheme="red" onClick={handleLeave} w="full">
                  탈퇴
                </Button>
              )}
              {isMaster && (
                <Button colorScheme="red" onClick={handleDisband} w="full">
                  공대 해산
                </Button>
              )}
            </HStack>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default RaidGroupDetailsModal;
