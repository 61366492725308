import React, { useEffect, useState } from 'react';
import {
  Box, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
  ModalBody, useDisclosure, SimpleGrid, Image, useColorModeValue, Button, Center,
} from '@chakra-ui/react';
import { fetchRaidGroupMemberStatus } from 'RaidGroup/api/RaidGroupApi';
import { fetchSchedulesGroupId } from './api/ScheduleApi';
import { RaidGroup } from 'RaidGroup/entity/RaidGroup';
import { Schedules } from './entity/Schedules';
import RaidUserAdd from './modals/RaidUserAdd';
import { translateDifficulty } from './common/ManageFunc';
import { useNavigate } from 'react-router-dom';

export const RaidGroupsAndSchedules: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [raidGroups, setRaidGroups] = useState<RaidGroup[]>([]);
  const [schedules, setSchedules] = useState<Schedules[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [selectedSchedule, setSelectedSchedule] = useState<Schedules | null>(null);
  const navigate = useNavigate();

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBg = useColorModeValue('white', 'gray.700');
  const hoverBgColor = useColorModeValue('blue.100', 'blue.500');
  const scheduleHoverBg = useColorModeValue('green.100', 'green.500');
  const textColor = useColorModeValue('gray.900', 'white');

  useEffect(() => {
    const loadRaidGroups = async () => {
      try {
        const groups = await fetchRaidGroupMemberStatus();
        setRaidGroups(groups);
      } catch (error) {
        console.error("Error loading raid groups", error);
      }
    };
    loadRaidGroups();
  }, []);

  const handleGroupClick = async (groupId: number) => {
    setSelectedGroupId(groupId);
    try {
      const loadedSchedules = await fetchSchedulesGroupId(groupId);
      setSchedules(loadedSchedules);
    } catch (error) {
      console.error("Error fetching schedules", error);
    }
  };

  const handleScheduleClick = (scheduleId: number) => {
    const selected = schedules.find(schedule => schedule.id === scheduleId);
    if (selected) {
      setSelectedSchedule(selected);
      onOpen();
    } else {
      console.error("Selected schedule not found in loaded schedules");
    }
  };

  const RaidGroupCard = ({ group, onClick }: { group: RaidGroup, onClick: () => void }) => {
    return (
      <Box
        key={group.id}
        p={4}
        shadow="xl"
        borderWidth="1px"
        borderRadius="lg"
        bg={cardBg}
        _hover={{ bg: hoverBgColor, color: 'white', transform: 'scale(1.05)', transition: 'all .2s ease-in-out' }}
        onClick={onClick}
        cursor="pointer"
        textAlign="center"
      >
        <Image
          src={group.raidGroupImage}
          boxSize="120px"
          objectFit="cover"
          alt={group.raidGroupName}
          m="auto"
          mb={3}
        />
        <Text fontSize="lg" fontWeight="bold" color={textColor}>{group.raidGroupName}</Text>
      </Box>
    );
  };

  return (
    <Box p={5} bg={bgColor}>
      <Text fontSize="2xl" mb={4} fontWeight="bold" color={textColor}>공대 선택</Text>
      {raidGroups.length > 0 ? (
        <SimpleGrid columns={[1, 2, 3, 4]} spacing={5}>
          {raidGroups.map(group => (
            <RaidGroupCard key={group.id} group={group} onClick={() => handleGroupClick(group.id!)} />
          ))}
        </SimpleGrid>
      ) : (
        <Center py={10}>
          <Button colorScheme="teal" onClick={() => navigate('/raidgroup')}>
            공대관리로~
          </Button>
        </Center>
      )}

      {selectedGroupId && (
        <>
          <Text fontSize="2xl" mt={6} mb={4} fontWeight="bold" color={textColor}>공대 레이드 일정</Text>
          {schedules.length > 0 ? (
            <SimpleGrid columns={[1, 2, 3, 4]} spacing={5}>
              {schedules.map(schedule => (
                <Box
                  key={schedule.id}
                  p={5}
                  shadow="lg"
                  borderWidth="1px"
                  borderRadius="lg"
                  bg={cardBg}
                  _hover={{ bg: scheduleHoverBg, color: 'white', transform: 'scale(1.05)', transition: 'all .2s ease-in-out' }}
                  onClick={() => handleScheduleClick(schedule.id)}
                  cursor="pointer"
                >
                  <Text fontSize="lg" fontWeight="bold" color={textColor}>{schedule.raidName}</Text>
                  <Text fontSize="sm" color={textColor}>{translateDifficulty(schedule.raidDifficulty)}</Text>
                  <Text fontSize="sm" color={textColor}>{new Date(schedule.scheduleDateTime).toLocaleDateString()}</Text>
                </Box>
              ))}
            </SimpleGrid>
          ) : (
            <Text fontSize="lg" color={textColor}>레이드 일정이 없습니다.</Text>
          )}
        </>
      )}

      {selectedSchedule && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>레이드 참가</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <RaidUserAdd scheduleId={selectedSchedule.id} />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default RaidGroupsAndSchedules;
