import { Flex, Heading, Box, useColorMode } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import GoogleLoginButton from "./googleLogin/GoogleLogin";

const Login: React.FC = () => {
  const { colorMode } = useColorMode();
  const navigate = useNavigate(); // useNavigate 훅을 사용하여 네비게이션 처리

  const handleLoginSuccess = () => {
    // 로그인 성공 후 이전 페이지로 이동
    navigate(-1);
  };

  return (
    <Flex
      h="100vh"
      alignItems="center"
      justifyContent="center"
      bg={colorMode === "light" ? "gray.100" : "gray.900"}
    >
      <Box
        p={8}
        maxWidth="400px"
        borderWidth={1}
        borderRadius="lg"
        boxShadow="lg"
        bg={colorMode === "light" ? "gray.100" : "gray.800"}
      >
        <Heading mb={6} textAlign="center" color={colorMode === "light" ? "teal.600" : "teal.300"}>
          로그인!
        </Heading>
        <Flex justifyContent="center">
          <GoogleLoginButton onSuccess={handleLoginSuccess} />
        </Flex>
      </Box>
    </Flex>
  );
};

export default Login;
