import { Flex, Box, IconButton, useBreakpointValue } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Sidebar from "layout/sidebar/Sidebar";
import { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";

export function UserLayout() {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false }) ?? false; // 모바일인지 여부 판단
  useEffect(() => {
    if (!isMobile) {
      setIsOpen(true); // PC 사이즈일 경우 항상 열려 있도록 설정
    } else {
      setIsOpen(false); // 모바일일 경우 닫힌 상태로 설정
    }
  }, [isMobile]);
  // 모바일인 경우 사이드바 상태를 토글할 수 있게 하고, PC인 경우 항상 열려 있게 함
  const handleSidebarToggle = () => {
    if (isMobile) {
      setIsOpen(!isOpen);
    }
  };

  return (
    <>
      {(!isMobile || isOpen) && (
        <Sidebar onClose={() => setIsOpen(false)} isMobile={isMobile} isOpen={isOpen} />
      )}
      <Flex
        position="relative"
        flexDir={isMobile ? "column" : "row"}
        ml={!isMobile && isOpen ? "256px" : "0"} // Sidebar 너비와 맞게 256px로 설정
        width={!isMobile && isOpen ? "calc(100% - 256px)" : "100%"} // 사이드바 너비를 제외한 나머지 너비 설정
        mt={isMobile && isOpen ? "100vh" : "0"} // 사이드바가 열렸을 때 다른 컴포넌트가 아래로 밀리도록 설정
      >
        {isMobile && (
          <IconButton
            aria-label="Toggle Sidebar"
            icon={<FiMenu />}
            onClick={handleSidebarToggle}
            position="fixed"
            top="20px"
            left="20px"
            zIndex="1500" // 콘텐츠 위에 표시되도록 설정
            variant="outline"
            colorScheme="teal"
            size="lg"
          />
        )}
        <Box flex="1" maxW="100%" p={5}>
          <Outlet />
        </Box>
      </Flex>
    </>
  );
}
