import React, { createContext, useCallback, useContext, useEffect, useState } from "react";

interface AuthContextType {
  isLoggedIn: boolean;
  isLoading: boolean;
  login: (token: string, nickname: string, refreshToken: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const logoutHandler = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userNickname");
  localStorage.removeItem("refreshToken");
  setTimeout(() => {
    window.location.href = "/signin";
  }, 500);
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const checkAuthorization = useCallback((): boolean => {
    const userToken = localStorage.getItem("token");
    return !!userToken;
  }, []);

  const login = useCallback((token: string, nickname: string, refreshToken: string) => {
    localStorage.setItem("token", token);
    localStorage.setItem("userNickname", nickname);
    localStorage.setItem("refreshToken", refreshToken);
    setIsLoggedIn(true);
  }, []);

  const logout = useCallback(() => {
    logoutHandler();
    setIsLoggedIn(false);
  }, []);

  const saveTokenFromUrl = useCallback(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const userToken = urlSearchParams.get("token");
    const userNickname = urlSearchParams.get("nickname");
    const refreshToken = urlSearchParams.get("refreshtoken");

    if (userNickname && userToken && refreshToken) {
      login(userToken, userNickname, refreshToken);
      window.location.href = "/";
    }
  }, [login]);

  useEffect(() => {
    const initializeAuth = async () => {
      setIsLoading(true);
      await saveTokenFromUrl();
      setIsLoggedIn(checkAuthorization());
      setIsLoading(false);
    };

    initializeAuth();
  }, [checkAuthorization, saveTokenFromUrl]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, isLoading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const auth = useContext(AuthContext);
  if (!auth) {
    throw new Error("useAuth must be used within an AuthProvider.");
  }
  return auth;
};
